import React from "react"

import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/2-min.jpg"
import styles from "./post-grid.module.css"

const JakRozpoznatVyzivovyMytus = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"5 tipů, jak rozpoznat výživový mýtus | NutritionPro"}
        description={
          "V této informacemi zaplavené době není vždy jednoduché se vyznat v tom, co je pravda a co lež. Každý se může vyjádřit, k čemu ho zrovna napadne, aniž by měl v dané oblasti nějaké vzdělání či odborné znalosti. Díky internetu se navíc tyto informace ihned rozletí do světa. Platí to bohužel i u výživy. Nepravdivá či zavádějící tvrzení se mohou snadno a rychle šířit, čemuž často přispívají nestudovaní výživový poradci, samozvaní odborníci, influenceři nebo různé celebrity. Bohužel mnohdy i média, jako jsou třeba časopisy, nebo i marketingové kampaně různých firem, které propagují své produkty. Jak tedy poznáte, že se jedná o mýtus?"
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            5 tipů, jak rozpoznat výživový mýtus
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="5 tipů, jak rozpoznat výživový mýtus"
              date="08.02.2023"
            />
            <div>
              <p className={styles.postText}>
                V této informacemi zaplavené době není vždy jednoduché se vyznat
                v tom, co je pravda a co lež.{" "}
                <b>
                  Každý se může vyjádřit, k čemu ho zrovna napadne, aniž by měl
                  v dané oblasti nějaké vzdělání či odborné znalosti.
                </b>{" "}
                Díky internetu se navíc tyto informace ihned rozletí do světa.
                Platí to bohužel i u výživy. Nepravdivá či zavádějící tvrzení se
                mohou <b>snadno a rychle šířit,</b> čemuž často přispívají
                nestudovaní výživový poradci, samozvaní odborníci, influenceři
                nebo různé celebrity. Bohužel mnohdy i média, jako jsou třeba
                časopisy, nebo i marketingové kampaně různých firem, které
                propagují své produkty. Jak tedy poznáte, že se jedná o mýtus?
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />

              <h5 className={styles.postTitle}>
                1. Řešení je až příliš snadné
              </h5>
              <p className={styles.postText}>
                Ať už se snažíte zhubnout, přibrat nebo jen zlepšit svou
                životosprávu, cesta k sebezdokonalení nebývá jednoduchá a{" "}
                <b>výsledky se rozhodně nedostaví jen tak zadarmo.</b> Změnit
                svůj životní styl je cesta na dlouhou trať, na které vás mohou
                potkat překážky, odříkání i neúspěch. Důležité je se{" "}
                <b>nevzdat a vytrvat a postupnými změnami se dopracovat</b>{" "}
                přesně tam, kde chcete být. Narazíte-li na produkt, například
                doplněk stravy, který vám nabízí snadné a rychlé řešení,
                pravděpodobně se bude jednat o <b>marketingovou kampaň.</b> Také
                pozor na věty typu “po této potravině zhubnete” nebo “tenhle
                produkt vás vyléčí”, ale i “poškodí vaše zdraví”.
              </p>

              <p className={styles.postText}>
                <b>
                  TIP:{" "}
                  <a
                    href="https://nutritionpro.cz/blog/post-2/"
                    target="_blank"
                  >
                    Detoxikace organismu.
                  </a>
                </b>{" "}
                Mýtus nebo ne?
              </p>

              <h5 className={styles.postTitle}>2. Neznámý zdroj informací</h5>
              <p className={styles.postText}>
                Informace, které si na internetu, nebo kdekoliv jinde, přečtete,
                je dobré si <b>ověřovat.</b> O to víc, pokud je autor článku{" "}
                <b>neznámý.</b> Není totiž jasné, zda má patřičné vzdělání nebo
                odborné znalosti. Také je dobré sledovat <b>zdroj</b> daných
                informací. Autor se může odkazovat na různé studie či profesory,
                ti ale vůbec <b>nemusí existovat</b> nebo se zabývat daným
                tématem. Pamatujte, že článek nerovná se studie a informace si
                vždy ověřujte.
              </p>

              <h5 className={styles.postTitle}>3. Démonizace potravin</h5>
              <p className={styles.postText}>
                Pokud článek, influencer nebo kdokoliv jiný démonizuje jednu
                konkrétní potravinu, složku potraviny nebo celou skupinu
                potravin, pravděpodobně se opět jedná o mystifikaci. Taková
                tvrzení obvykle prohlašují, že{" "}
                <b>
                  po vyřazení této potraviny nebo celé skupiny potravin zhubnete
                </b>{" "}
                nebo se vyléčíte z různých nemocí. Naopak jejich konzumace
                způsobuje tloustnutí a onemocnění. Příkladem může být
                doporučování omezení konzumace pečiva (která je samozřejmě
                naprosto v pořádku).
              </p>

              <h5 className={styles.postTitle}>4. Šokující informace</h5>
              <p className={styles.postText}>
                Pokud vás nějaký výživový výrok <b>pořádně zarazí,</b> je dost
                možné, že se jedná o mýtus. Čím více je informace šokující, tím
                je pravděpodobnější, že se jedná o výmysl. Nové poznatky ve vědě
                totiž <b>nemají za úkol vás šokovat,</b> ale objektivně předat
                novou informaci. Šokovat určitou informací může být opět
                marketingová strategie.
              </p>

              <h5 className={styles.postTitle}>5. Agresivní sdělení</h5>
              <p className={styles.postText}>
                To samé platí i u agresivních zpráv.{" "}
                <b>
                  Čím agresivnější a urputnější sdělení je, tím pravděpodobněji
                  se opět jedná o mýtus.
                </b>{" "}
                Odborné studie totiž nemají vyvolávat strach, pouze informovat o
                nových poznatcích. Určitě se v žádné studii nedočtete o tom, že
                musíte udělat to či ono, jinak se stanou strašné věci. Opět zde
                nezapomínejte, že článek na internetu není odbornou studií, může
                z nich ovšem čerpat informace.
              </p>

              <h5 className={styles.postTitle}>Jak se před mýty chránit</h5>
              <p className={styles.postText}>
                Jak už jsem zmínila několikrát, ideálním řešením, jak se mýtům
                bránit, je si <b>nově získané informace vždy ověřit.</b> Nevěřte
                všemu, co se říká, i když to říká influencer se stovkami tisíc
                sledujících nebo vaše oblíbená celebrita.{" "}
                <b>Přemýšlejte kriticky.</b> Spoléhejte na váš selský rozum,
                který vám zajisté napoví, jestli se jedná o úplný nesmysl nebo
                ne. <b>Nesnažte se hledat zkratky, neexistují.</b> Zbytečně si
                tak svoji cestu prodloužíte. Zaměřte se raději na malé, sice
                pomalejší, ale udržitelné kroky, které vás ve výsledku{" "}
                <b>dovedou přesně tam, kam potřebujete.</b>
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Jak na zdravé a udržitelné hubnutí se dozvíte{" "}
                <a
                  href="https://nutritionpro.cz/blog/zdrave-udrzitelne-hubnuti/"
                  target="_blank"
                >
                  zde
                </a>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default JakRozpoznatVyzivovyMytus
